const labels = {
  title: 'Aviso',
  plural: 'Avisos',
  upper: 'Aviso',
  upperPlural: 'Avisos',
  lower: 'aviso',
  lowerPlural: 'avisos',
}
export default {
  title: labels.title,
  plural: labels.plural,
  upper: labels.upper,
  upperPlural: labels.upperPlural,
  lower: labels.lower,
  lowerPlural: labels.lowerPlural,
  headers: {

    id: {
      label: "ID",
      key: "id",
    },
    label: {
      label: "Nome",
      key: "name",
    },
    description: {
      label: "Descrição",
      key: "description",
    },
    image: {
      label: "imagem",
      key: "image",
    },
    video: {
      label: "Vídeo",
      key: "video",
    },
    audio: {
      label: "Áudio",
      key: "audio",
    },
    link: {
      label: "Link",
      key: "link",
    },
    status: {
      label: "Status",
      key: "status",
    },
    send: {
      label: "Enviar",
      key: "send",
    },
    aproval: {
      label: "Aprovar",
      key: "aproval",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
  },
  erroLoadPaginate: `Erro ao carregar dados de ${labels.lower}.`,
  erroLoadTrash: `Erro ao carregar dados de ${labels.lower} da lixeira.`,
  itemRemovidoLixeira: `${labels.title} movido pra lixeira com sucesso.`,
  itemRemovidoLixeiraErro: `Erro ao remover o ${labels.lower} para a lixeira.`,
  itemExcuidoLixeira: `${labels.title} excluído da lixeira com sucesso.`,
  itemExcuidoLixeiraErro: `Erro ao excluir o ${labels.lower} da lixeira, existem itens vinculados a esse registro.`,
  itemRestauradoLixeira: `${labels.title} restaurado com sucesso.`,
  itemRestauradoLixeiraErro: `Erro ao restaurar o ${labels.lower}.`,
  createTitle: `Novo ${labels.lower}`,
  create: `${labels.title} criado com sucesso.`,
  update: `${labels.title} editado com sucesso.`,
  updateTitle: `Editar ${labels.lower}`,
  createError: `Erro ao criar o ${labels.lower}.`,
  updateError: `Erro ao editar a ${labels.lower}.`,
  inputs: {
    id: null,
    label: null,
    description: null,
    image: null,
    video: null,
    audio: null,
    link: null,
    status: null,
    send: null,
    aproval: null,
    peoples:[],
    classes:[],
    peoples_types:[],
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: `ID do ${labels.lower}`,
      example: "1",
    },
    label: {
      label: `Nome do ${labels.lower}`,
      description: `Nome do ${labels.lower}`,
      example: "Aula cancelada",
    },
    description: {
      label: "Descrição",
      description: `Descrição do ${labels.lower}`,
      example: "Não haverá aula dia 20",
    },
    image: {
      label: "Imagem",
      description: `Imagem do ${labels.lower}`,
      example: "avisos/1.jpg",
    },
    video: {
      label: "Vídeo",
      description: `Vídeo do ${labels.lower}`,
      example: "avisos/1.mp4",
    },
    audio: {
      label: "Áudio",
      description: `Áudio do ${labels.lower}`,
      example: "avisos/1.mp3",
    },
    link: {
      label: "Link",
      description: `Link do ${labels.lower}`,
      example: "https://www.gestio.com/aviso/1",
    },
    status: {
      label: "Status",
      description: `Status do ${labels.lower}`,
      example: "Ativo/inativo",
      options: [
        { value: 1, label: "Ativo" },
        { value: 2, label: "Inativo" },
      ],
    },
    send: {
      label: "Enviar",
      description: `Enviar ${labels.lower}`,
      example: "Sim/Não",
        options: [
            { value: 1, label: "Sim" },
            { value: 2, label: "Não" },
        ],
    },
    aproval: {
      label: "Aprovar",
      description: `Aprovar ${labels.lower}`,
      example: "Sim/Não",
        options: [
            { value: 1, label: "Sim" },
            { value: 2, label: "Não" },
        ],
    },
    created_at: {
      label: "Data de criação",
      description: `Data de criação do ${labels.lower}`,
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: `Data da última atualização do ${labels.lower}`,
      example: "2021-01-01 00:00:00",
    }
  }
}
